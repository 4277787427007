import { Box, Typography } from "@mui/material";
import { useFormFieldStyles } from "components/layout/forms/FormField";
import { makeStyles } from "components/providers/makeStyles";
import { T } from "components/util/t";

const useStyles = makeStyles({
    fieldset: {
        "&[disabled]": {
            opacity: 0.6,
            "&, & *": {
                cursor: "not-allowed"
            }
        }
    }
});

export const Fieldset = ({ field: { label, help, disabled }, children }) => {
    const classes = { ...useFormFieldStyles(), ...useStyles() };

    return (
        <Box component="fieldset" margin={0} padding={0} border={0} disabled={disabled} className={classes.fieldset}>
            {label && <Typography variant="label1" className={classes.label} component="label">
                <T>{label}</T>
            </Typography>}
            {help && <Typography variant="caption" component="p" className={classes.help}><T>{help}</T></Typography>}
            {children}
        </Box>
    );
};
