import { DialogProps } from "@mui/material";
import { useState } from "react";

export const useDialogState = (props: {disableBackdropClick?: boolean} = {}): [
    dialogOpen: boolean,
    openDialog: () => void,
    closeDialog: DialogProps["onClose"]
] => {
    const { disableBackdropClick } = props;
    const [dialogOpen, setDialogOpen] = useState(false);
    const openDialog = () => setDialogOpen(true);
    const closeDialog: DialogProps["onClose"] = (e, reason) => {
        // @ts-expect-error MUI too permissive with types
        e?.stopPropagation?.();
        if (disableBackdropClick) {
            if (reason !== "backdropClick") {
                setDialogOpen(false);
                return true;
            } else {
                return false;
            }
        }
        else setDialogOpen(false);
    };

    return [dialogOpen, openDialog, closeDialog];
};
