import { makeStyles } from "../../providers/makeStyles";

export const useSimpleListStyles = makeStyles((theme, { borderBottom, borderTop, padding = 2, onClick, disabled, highlightOnHover }) => ({
    limitContent: {
        display: "-webkit-box",
        WebkitLineClamp: "1" ,
        lineClamp: "1",
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        wordBreak: "break-all"
    },
    item: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        paddingTop: theme.spacing(padding),
        paddingBottom: theme.spacing(padding),
        borderTop: borderTop && `${borderTop}px solid`,
        borderBottom: borderBottom && `${borderBottom}px solid`,
        borderColor: theme.palette.border.light,
        cursor: onClick && !disabled ? "pointer" : "inherit",
        opacity: disabled ? 0.5 : 1,
        "@media (hover: hover)": {
            "&:hover": {
                background: highlightOnHover && theme.palette.tertiary.lighter,
            }
        }
    },
    content: {
        display: "flex",
        flexDirection: "column",
    },
    aside: {
        flexShrink: 0,
    }
}));
