import { NavLink } from "react-router-dom";
import { Link, Menu, MenuItem, Typography } from "@mui/material";
import { makeStyles } from "components/providers/makeStyles";
import { ChevronRight } from "@mui/icons-material";

import { useMiniButtonStyles } from "../commonStyles/buttonStyles";
import { noop } from "../../forms";
import { T } from "../../util/t";

const useRunEventMenuStyles = makeStyles(theme => ({
    chevronDown: {
        transform: "rotate(90deg)",
        color: theme.palette.primary.main,
        marginLeft: theme.spacing(1)
    },
    menuItems: {
        "& > a": {
            whiteSpace: "normal"
        }
    }
}));

export const RunEventMenu = ({ isMobile,  anchorEl, openMenu, closeMenu, clickLink = noop, triggered }) => {
    const classes = { ...useMiniButtonStyles(), ...useRunEventMenuStyles() };

    const items = (
        <div className={classes.menuItems}>
            {isMobile && <Typography variant="label2" color="textSecondary" component="li" textTransform="uppercase" px={1.5} py={1}><T>New organisations</T></Typography>}

            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com" target="_blank">
                <T>Features</T>
            </MenuItem>
            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com/pricing/" target="_blank">
                <T>Pricing</T>
            </MenuItem>
            <MenuItem onClick={closeMenu} component={Link} href="https://organiser.liveheats.com/contact/?_gl=1*18jx4nz*_ga*MjA5NjY0ODgwMC4xNzEzNTAwOTQz*_ga_ZHZ05GLC7Y*MTcxNjI5NTQ5NC4xMTguMS4xNzE2Mjk1NzEyLjAuMC4w" target="_blank">
                <T>Get a demo</T>
            </MenuItem>
            <MenuItem onClick={closeMenu} component={NavLink} to="/organisations/new">
                <T>Create a new organisation</T>
            </MenuItem>
        </div>
    );

    return (
        <>
            <>
                {!isMobile && <Link variant="subtitle2" underline="none" onClick={openMenu}><T>New organisations</T><ChevronRight className={classes.chevronDown} /></Link>}
                <Menu
                        anchorEl={anchorEl}
                        marginThreshold={4}
                        open={!!anchorEl}
                        onClose={closeMenu}
                >
                    {items}
                </Menu>
            </>
        </>
    );
};
