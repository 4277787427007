import classnames from "classnames";
import { useId } from "react";
import { makeStyles } from "components/providers/makeStyles";
import { Typography } from "@mui/material";

import { DateField } from "./fields/DateField";
import { Radio } from "./fields/Radio";
import { Select } from "./fields/Select";
import { Input } from "./fields/Input";
import { Checkbox } from "./fields/Checkbox";
import { T } from "../../util/t";
import { ChipSelect } from "./fields/ChipSelect";
import { SearchSelect } from "./fields/Autocomplete/SearchSelect";
import { CreatableSearchSelect } from "./fields/Autocomplete/CreatableSearchSelect";
import { SearchSelectVirtualized } from "./fields/Autocomplete/SearchSelectVirtualized";
import { CreatableSearchSelectVirtualized } from "./fields/Autocomplete/CreatableSearchSelectVirtualized";
import { Textarea } from "./fields/Textarea";
import { HelpTooltip } from "../../elements/helpTooltip";
import { Tooltip } from "../../elements/tooltip";
import { Lock } from "@mui/icons-material";

export const useFormFieldStyles =  makeStyles((theme, { width }) => ({
    field: {
        marginBottom: theme.spacing(2),
    },
    label: {
        display: "block",
        marginBottom: theme.spacing(1),
    },
    checkboxLabel: {
        display: "inline",
        verticalAlign: "super",
        paddingLeft: theme.spacing(1)
    },
    help: {
        marginBottom: theme.spacing(1),
    },
    inputWrapper: {
        ".MuiAutocomplete-root": {
            backgroundColor: theme.palette.background.paper,
        },
        position: "relative",
        width: width,
        "&&": {
            [theme.breakpoints.down("sm")]: {
                width: "100%"
            }
        }
    },
    locked: {
        position: "absolute",
        top: 41,
        right: 10,
    }
}));
export const FormFieldWrapper = ({ label, helpTooltip, children }) => {
    const classes = useFormFieldStyles();

    return (
        <div className={classes.field}>
            <Typography variant="label1" component="label" className={classes.label}>
                <T>{label}</T>
                {helpTooltip && <HelpTooltip><T>{helpTooltip}</T></HelpTooltip>}
            </Typography>
            {children}
        </div>
    );
};

const fieldByType = {
    date: DateField,
    radio: Radio,
    checkbox: Checkbox,
    select: Select,
    searchSelect: SearchSelect,
    creatableSearchSelect: CreatableSearchSelect,
    searchSelectVirtualized: SearchSelectVirtualized,
    creatableSearchSelectVirtualized: CreatableSearchSelectVirtualized,
    chipSelect: ChipSelect,
    textarea: Textarea
};
const getField = type => fieldByType[type] || Input;
export const FormField = ({ field, input, error, validating, isDirty, children }) => {
    const classes = useFormFieldStyles({ width: field.width });
    const id = useId();
    const Component = getField(field.type);
    const displayErrorMessage = field.displayErrorMessage ?? true;
    field.inputProps = { ...field.inputProps, id };

    return (
        <div className={classes.field}>
            <div className={classes.inputWrapper}>
                {field.label && field.type !== "checkbox" &&
                <Typography variant="label1" className={classes.label} component="label" htmlFor={id}>
                    <T>{field.label}</T>
                    {field.helpTooltip && <HelpTooltip><T>{field.helpTooltip}</T></HelpTooltip>}
                </Typography>}

                {field.help && <Typography variant="caption" component="p" className={classes.help}><T>{field.help}</T></Typography>}

                <Component field={field} input={input} hasError={!!error}/>
                {field.label && field.type === "checkbox" &&
                <Typography className={`${classes.label} ${classes.checkboxLabel}`} component="label" htmlFor={id}>
                    <T>{field.label}</T>
                    {field.helpTooltip && <HelpTooltip><T>{field.helpTooltip}</T></HelpTooltip>}
                </Typography>}

                {field.restricted && <div className={classes.locked}>
                    <Tooltip title={<T fieldName={field.label} contactEmail={field.contactEmail}>restricted_field</T>}>
                        <Typography variant="inherit">
                            <Lock />
                        </Typography>
                    </Tooltip>
                </div>}
            </div>
            {children}
            {displayErrorMessage && <FieldMessage
                isDirty={isDirty}
                errorMessage={error?.message}
                checkingMessage={field.checkingMessage}
                validMessage={field.validMessage}
                validating={validating}
            />}
        </div>
    );
};

export const useMessageStyles = makeStyles(theme => ({
    message: {
        fontSize: theme.typography.caption.fontSize,
        lineHeight: theme.spacing(3),
        minHeight: theme.spacing(3),
        "&::first-letter": {
            textTransform: "uppercase"
        }
    },
    errorMessage: {
        color: theme.palette.input.error,
    },
    successMessage: {
        color: theme.palette.input.success,
    }
}));
export const FieldMessage = ({ isDirty, errorMessage, validMessage, checkingMessage, validating, infoMessage }) => {
    const classes = useMessageStyles();

    if (validating && checkingMessage)
        return <div className={classes.message}><T>{checkingMessage}</T></div>;

    if (!errorMessage && infoMessage) {
        return <div className={classes.message}><T>{infoMessage}</T></div>;
    }

    if (isDirty && !errorMessage && validMessage)
        return <div className={classnames(classes.message, classes.successMessage)}><T>{validMessage}</T></div>;

    return <div className={classnames(classes.message, classes.errorMessage)}>{errorMessage && <T>{errorMessage}</T>}</div>;
};
