import { createContext, useContext, useEffect, useState } from "react";
import { useCurrentUser } from "../CurrentUserProvider";

export const RealtimeContext = createContext({});
RealtimeContext.displayName = "Ably";

export const useBindToChannelEvent = (channelOpts, event, handler) =>
    useContext(RealtimeContext).useChannel?.(channelOpts, event, message => handler(message.data));

export const DetachableChannelProvider = (channelProviderProps) => {
    const ablyClient = useContext(RealtimeContext).useAbly?.();
    const { channelName } = channelProviderProps;

    useEffect(() => {
        if (ablyClient) {
            ablyClient.channels.get(channelName).attach();
            return () => {
                const channel = ablyClient.channels.get(channelName);
                setTimeout(async () => {
                    if ((channel.state === "attaching" || channel.state === "attached") &&
                        channel.subscriptions.any.length === 0 &&
                        channel.subscriptions.anyOnce.length === 0 &&
                        Object.keys(channel.subscriptions.events).length === 0 &&
                        Object.keys(channel.subscriptions.eventsOnce).length === 0 &&
                        channel.presence.subscriptions.any.length === 0 &&
                        channel.presence.subscriptions.anyOnce.length === 0 &&
                        Object.keys(channel.presence.subscriptions.events).length === 0 &&
                        Object.keys(channel.presence.subscriptions.eventsOnce).length === 0
                    ) {
                        await channel.detach();
                    }
                }, 2500);
            };
        }
    }, [ablyClient, channelName]);

    const ChannelProvider = useContext(RealtimeContext).ChannelProvider;

    return ChannelProvider ? <ChannelProvider {...channelProviderProps}/> : channelProviderProps.children;
};

const createClient = ({ BaseRealtime, WebSocketTransport, FetchRequest, RealtimePresence }) => new BaseRealtime({
    authUrl: "/api/realtime_auth",
    authMethod: "POST",
    plugins: {
        WebSocketTransport,
        FetchRequest,
        RealtimePresence,
    }
});

export const RealtimeProvider = ({ children }) => {
    const [Ably, setAbly] = useState();
    const [client, setClient] = useState(null);
    const [module, setModule] = useState({});
    const { loaded, currentUser } = useCurrentUser();

    useEffect(() => {
        import(/* webpackChunkName: "ably" */ "ably/modular").then(ably => setAbly(ably));
        import(/* webpackChunkName: "ably" */ "ably/react").then(module => setModule(module));
    }, []);

    useEffect(() => {
        if (Ably && module && loaded) {
            setClient(prevClient => {
                prevClient?.close();
                return createClient(Ably);
            });
        }
    }, [loaded, currentUser, Ably, module]);

    return (client && module) ?
        <module.AblyProvider client={client}>
            <RealtimeContext.Provider value={module}>
                {children}
            </RealtimeContext.Provider>
        </module.AblyProvider> :
        children;
};
