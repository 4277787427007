import { ChevronRight } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { useSimpleListStyles } from "./useSimpleListStyles";

export const SimpleList = ({ children, hideLastBorder, variant = "medium" }) => (
    <Stack component="ul" margin={0} padding={0}>
        {children.map((item, i) =>
            item.to ?
                <SimpleListItemLink {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} /> :
                <SimpleListItem {...item} key={item.key || i} variant={variant} borderBottom={hideLastBorder && i === children.length - 1 ? 0 : item.borderBottom} />
        )}
    </Stack>
);


export const SimpleListItemLink = ({ to, target, rel, ...rest }) => (
    <Link to={to} target={target} rel={rel}>
        <SimpleListItem {...rest} aside={rest.aside || <ChevronRight />} />
    </Link>
);

const SimpleListItemMedium = ({ title, subtitle, description, aside, borderBottom = 1, limitDescription = false, limitTitle = false }) => {
    const classes = useSimpleListStyles({ borderBottom });

    return (
        <li className={classes.item}>
            <div className={classes.content}>
                <Typography variant="button" component="div" className={classnames(limitTitle && classes.limitContent)}>{title}</Typography>
                {subtitle && <Typography>{subtitle}</Typography>}
                {description && <Typography color="textSecondary" marginTop={1} className={classnames(limitDescription && classes.limitContent)}>{description}</Typography>}
            </div>
            <Typography className={classes.aside} component="div">{aside}</Typography>
        </li>
    );
};

const SimpleListItemSmall = ({ subtitle, description, aside, onClick, borderBottom = 0, highlightOnHover }) => {
    const classes = useSimpleListStyles({ borderBottom, padding: 0.5, onClick, highlightOnHover });

    return (
        <li className={classes.item} onClick={onClick}>
            <div className={classes.content}>
                <Typography component="div">{subtitle}</Typography>
                <Typography color="textSecondary" variant="caption">{description}</Typography>
            </div>
            <div className={classes.aside}>{aside}</div>
        </li>
    );
};

const VARIANTS = {
    medium: SimpleListItemMedium,
    small: SimpleListItemSmall
};

export const SimpleListItem = ({ variant = "medium", ...rest }) => {
    const ListComponent = VARIANTS[variant];

    return <ListComponent {...rest} />;
};
