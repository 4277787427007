import { Dialog, useTheme, useMediaQuery, DialogProps } from "@mui/material";

export const ResponsiveDialog: React.FC<{children: React.ReactNode} & DialogProps> = ({ children, ...dialogProps }) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <Dialog fullScreen={fullScreen} {...dialogProps}>
            {children}
        </Dialog>
    );
};
