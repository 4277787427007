import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { Field } from "./Field";
import { FormWrapper } from "../layout/forms/FormWrapper";
import { DialogFormWrapper } from "../layout/forms/DialogFormWrapper";
import { Box, Stack } from "@mui/material";
import { Fieldset } from "./Fieldset";

const FieldComponent = ({ field, control, getValues, setValue, trigger, watch }) =>
    field.type === "fieldset" ?
        <Fieldset field={field}>
            <FieldComponents fields={field.fields} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch} />
        </Fieldset> :
        field.component ?
            <field.component key={field.name} field={field} control={control} getValues={getValues} setValue={setValue} trigger={trigger}/> :
            <Field key={field.name} field={field} getValues={getValues} setValue={setValue} control={control} trigger={trigger}/>;

export const FieldComponents = ({ fields, control, getValues, setValue, trigger, watch }) =>
    fields.filter(f => f && (!f.renderIf || f.renderIf(watch))).map((field, i) =>
        Array.isArray(field) ?
            <Stack key={i} spacing={[2, 4]} direction="row" flexBasis={1}>
                {field.filter(f => f).map(innerField =>
                    <Box key={innerField.name} flex={1}>
                        <FieldComponent field={innerField} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch}/>
                    </Box>
                )}
            </Stack> :
            <FieldComponent key={i} field={field} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch}/>
    );

export const useFormKit = ({ fields, initialValues, resetOnSubmit }) => {
    const { handleSubmit, control, getValues, setValue, formState: { isSubmitSuccessful }, reset, setError, trigger, watch } = useForm({
        mode: "onChange",
        defaultValues: initialValues,
    });

    useEffect(() => {
        if (isSubmitSuccessful && resetOnSubmit) reset();
    }, [isSubmitSuccessful, resetOnSubmit]);

    return {
        components: <FieldComponents fields={fields} control={control} getValues={getValues} setValue={setValue} trigger={trigger} watch={watch} />,
        control,
        handleSubmit,
        setError,
        getValues,
        setValue,
        trigger
    };
};

export const FormKit = ({
    fields,
    initialValues,
    onSubmit,
    resetOnSubmit,
    fullWidthSubmitButton,
    enableOnPristine,
    onCancel,
    buttonLabel,
    buttonEndIcon,
    classNames,
    secondaryAction,
    formCaption,
    FormWrapperComponent = FormWrapper,
    footer,
    children
}) => {
    const { handleSubmit, control, components, getValues, setValue, setError, trigger } = useFormKit({ fields, initialValues, resetOnSubmit });

    const submit = (values, e) =>
        onSubmit(values, e).catch(e => {
            if (e?.response?.status === 422) {
                Object.keys(e.response.data?.errors || {}).forEach(field =>
                    setError(field, {
                        type: "manual",
                        message: e.response.data.errors[field]
                    }, { shouldFocus: true })
                );
            } else {
                throw e;
            }
        });

    return (
        <FormProvider control={control} trigger={trigger} getValues={getValues} setValue={setValue}>
            <FormWrapperComponent
                control={control}
                fullWidthSubmitButton={fullWidthSubmitButton}
                enableOnPristine={enableOnPristine}
                handleSubmit={handleSubmit(submit)}
                buttonLabel={buttonLabel}
                buttonEndIcon={buttonEndIcon}
                formCaption={formCaption}
                onCancel={onCancel}
                classNames={classNames}
                secondaryAction={secondaryAction}
            >
                {children}
                {components}
                {footer}
            </FormWrapperComponent>
        </FormProvider>
    );
};

export const DialogFormKit = props => <FormKit {...props} FormWrapperComponent={DialogFormWrapper}/>;
