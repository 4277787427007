import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { FormField } from "../layout/forms/FormField";
import { ISO_DATE } from "utils/dates";
import { useController } from "react-hook-form";
import { useState } from "react";

const identity = v => v;
const defaultFormat = value => value != null ? value : "";

dayjs.extend(utc);

export const createValidateField = (schema, setValidating) =>
    value => {
        setValidating?.(true);
        return schema.validate(value)
            .then(() => undefined)
            .catch(e => e.message)
            .then(message => {
                setValidating?.(false);
                return message;
            });
    };

export const parseOptionToValue = (value, field) => field.autocompleteProps?.multiple
    ? value?.map(o => typeof o === "string" ? o : o.value)
    : value?.value;
export const formatValueToOption = (value, field) => field.autocompleteProps?.multiple
    ? (value ? value.map(value => field.options.find(o => o.value === value) || { label: value, value }) : [])
    : (field.options.find(o => o.value === value) || null);
export const extraPropsForFieldType = {
    date: {
        format: value => {
            if (value) {
                const date = new Date(value);
                const timezoneOffset = date.getTimezoneOffset() * 60000;
                return new Date(date.getTime() + timezoneOffset);
            }
            return "";
        },
        parse: value =>  value ? dayjs(value).format(ISO_DATE) : ""
    },
    radio: {
        parse: value => value === "true"
    },
    searchSelect: {
        parse: parseOptionToValue,
        format: formatValueToOption
    },
    searchSelectVirtualized: {
        parse: parseOptionToValue,
        format: formatValueToOption
    },
    creatableSearchSelect: {
        parse: parseOptionToValue,
        format: formatValueToOption
    },
    creatableSearchSelectVirtualized: {
        parse: parseOptionToValue,
        format: formatValueToOption
    }
};

export const useField = ({ field, control, getValues, setValue, trigger }) => {
    const [validating, setValidating] = useState(false);
    const { field: inputField, fieldState } = useController({
        control,
        name: field.name,
        rules: field.validation && { validate: createValidateField(field.validation, setValidating) }
    });
    const value = (field.format || extraPropsForFieldType[field.type]?.format || defaultFormat)(inputField.value, field);

    const input = {
        ...inputField,
        value,
        onChange: value => {
            const eventValue = value?.target ? field.type === "checkbox" ? value.target.checked : value.target?.value : value;
            const parsedValue = (field.parse || extraPropsForFieldType[field.type]?.parse || identity)(eventValue, field);
            inputField.onChange(parsedValue);
            field.onChange?.({ parsedValue, getValues, setValue, trigger });
        }
    };

    return { input, fieldState, validating };
};

export const Field = ({ field, control, getValues, setValue, trigger, children }) => {
    const { input, fieldState, validating } = useField({ field, control, getValues, setValue, trigger });

    return <FormField field={field} input={input} error={fieldState.error} isDirty={fieldState.isDirty} validating={validating} children={children}/>;
};
